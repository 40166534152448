import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Select, Popover  } from 'antd';
import { adminServices } from '../../services/adminServices';
import { photoServices } from '../../services/photoServices';
import ShareProfile from '../../components/Share';
import './admin.css';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [profiles, setProfile] = useState([]);
  const [matches, setMatches] = useState([]);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [isMatchModalVisible, setIsMatchModalVisible] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [supportRequests, setSupportRequests] = useState([]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [userPhotos, setUserPhotos] = useState({});

// Add photo fetching in useEffect
useEffect(() => {
  const fetchPhotos = async () => {
    for (const user of users) {
      try {
        const photos = await photoServices.getAllPhotos(user.user_id);
        if (photos && photos.length > 0) {
          setUserPhotos(prev => ({
            ...prev,
            [user.user_id]: photos[0].image_data
          }));
        }
      } catch (error) {
        console.error('Error fetching photos:', error);
      }
    }
  };
  if (users.length > 0) {
    fetchPhotos();
  }
}, [users]);

  useEffect(() => {
    fetchUsers();
    fetchProfiles();
    fetchMatches();
    fetchSupportRequests();
  }, []);

  useEffect(() => {
    const verifyAdminAccess = async () => {
      const token = localStorage.getItem('accessToken');
      try {
        await adminServices.checkAdminAccess(token);
      } catch (error) {
        message.error('Unauthorized access');
        window.location.href = '/';
      }
    };
    verifyAdminAccess();
  }, []);

  //fetching data functions
  const fetchUsers = async () => {
    try {
      const data = await adminServices.getAllUsers();
      setUsers(data);
    } catch (error) {
      message.error('Failed to fetch users');
    }
  };

  const fetchProfiles = async () => {
    try {
      const data = await adminServices.getAllProfiles();
      setProfile(data);
    } catch (error) {
      message.error('Failed to fetch profiles');
    }
  };

  const fetchMatches = async () => {
    try {
      const response = await adminServices.getMatches();
      setMatches(response);
    } catch (error) {
      message.error('Failed to fetch matches');
    }
  };

  const fetchSupportRequests = async () => {
    try {
      const data = await adminServices.getSupportRequests();
      setSupportRequests(data);
    } catch (error) {
      message.error('Failed to fetch support requests');
    }
  };

  //data manipulation functions
  const handleDeleteUser = async (user_id) => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: 'Are you sure you want to delete this user? This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk: async () => {
        try {
          await adminServices.deleteUser(user_id);
          message.success('User deleted successfully');
          fetchUsers();
        } catch (error) {
          message.error('Failed to delete User');
        }
      }
    });
  };

  const handleUpdateProfile = async (values) => {
    try {
      await adminServices.updateProfile(values);
      message.success('Profile updated successfully');
      setIsProfileModalVisible(false);
      fetchProfiles();
    } catch (error) {
      message.error('Failed to update profile');
    }
  };

  const handleDeleteProfile = async (profile_id) => {
  Modal.confirm({
    title: 'Confirm Deletion',
    content: 'Are you sure you want to delete this profile? This action cannot be undone.',
    okText: 'Yes, Delete',
    okType: 'danger',
    cancelText: 'No, Cancel',
    onOk: async () => {
      try {
        await adminServices.deleteProfile(profile_id);
        message.success('Profile deleted successfully');
        fetchProfiles();
      } catch (error) {
        message.error('Failed to delete profile');
      }
    }
  });
};

  const handleUpdateMatch = async (values) => {
    try {
      await adminServices.updateMatch(currentMatch.match_id, values.status);
      message.success('Match updated successfully');
      setIsMatchModalVisible(false);
      fetchMatches();
    } catch (error) {
      message.error('Failed to update match');
    }
  };

  const handleDeleteMatch = async (record) => {
    try {
      await adminServices.deleteMatch(record.match_id);
      message.success('Match deleted successfully');
      fetchMatches();
    } catch (error) {
      message.error('Failed to delete match');
    }
  };

  const handleDeleteSupportRequest = async (requestId) => {
    try {
      await adminServices.deleteSupportRequest(requestId);
      message.success('Support request deleted successfully');
      fetchSupportRequests();
    } catch (error) {
      message.error('Failed to delete support request');
    }
  };

  const handleExportUsers = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const blob = await adminServices.exportUserProfiles(token);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = "rj_user_profiles_data.xlsx";
      link.click();
      window.URL.revokeObjectURL(url);
      message.success('Export successful');
    } catch (error) {
      message.error('Failed to export users');
    }
  };

  const handleCreateUserAndProfile = async (values) => {
    try {
      await adminServices.createUserAndProfile(values);
      message.success('User and profile created successfully');
      setIsCreateModalVisible(false);
      fetchUsers();
      fetchProfiles();
    } catch (error) {
      message.error('Failed to create user and profile');
    }
  };

  //columns to display 
  const userColumns = [
    {
      title: 'Sr.No',
      key: 'serialNumber',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Photo',
      key: 'photo',
      render: (_, record) => (
        <div className="user-photo">
          {userPhotos[record.user_id] ? (
            <img 
              src={`data:image/jpeg;base64,${userPhotos[record.user_id]}`}
              alt={record.name}
              style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }}
            />
          ) : (
            <div className="photo-placeholder" style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {record.name[0].toUpperCase()}
            </div>
          )}
        </div>
      )
    },
  
    {
      title: 'Name', dataIndex: 'name', key: 'name',
      render: (text, record) => (
        <a href={`/userprofile/${record.user_id}`} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      )
    },
    { title: 'Mobile', dataIndex: 'mobile_number', key: 'mobile_number' },
    { title: 'User type', dataIndex: 'user_type', key: 'user_type' },
    { title: 'Looking for', dataIndex: 'looking_for', key: 'looking_for' },

    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleString()
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
        <Button
          className="action-button"
          onClick={() => handleDeleteUser(record.user_id)}
          danger
        >
          Delete
        </Button>
        <Popover
          content={<ShareProfile userId={record.user_id} userName={record.name} />}
          title={`Share ${record.name}'s Profile`}
          trigger="click"
          placement="left"
        >
          <Button className="action-button" type="primary">
            Share
          </Button>
        </Popover>
      </>
      ),
    },
  ];

  const profileColumns = [
    {
      title: 'Sr.No',
      key: 'serialNumber',
      render: (_, __, index) => index + 1,
    },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Gender', dataIndex: 'gender', key: 'gender' },
    { title: 'Age', dataIndex: 'age', key: 'age' },
    { title: 'Religion', dataIndex: 'religion', key: 'religion' },

    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button className="action-button" onClick={() => { setCurrentProfile(record); setIsProfileModalVisible(true); }}>Update</Button>
          <Button className="action-button" onClick={() => handleDeleteProfile(record.profile_id)} danger>Delete</Button>
        </>
      ),
    },
  ];

  const matchColumns = [
    {
      title: 'Sr.No',
      key: 'serialNumber',
      render: (_, __, index) => index + 1,
    },
    { title: 'Name', dataIndex: 'matcher_name', key: 'matcher_name' },
    { title: 'Matched Name', dataIndex: 'matched_user_name', key: 'matched_user_name' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button className="action-button" onClick={() => { setCurrentMatch(record); setIsMatchModalVisible(true); }}>Update</Button>
          <Button className="action-button" onClick={() => handleDeleteMatch(record)} danger>Delete</Button>
        </>
      ),
    },
  ];

  const supportRequestColumns = [
    {
      title: 'Sr.No',
      key: 'serialNumber',
      render: (_, __, index) => index + 1,
    },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Mobile', dataIndex: 'mobile_number', key: 'mobile_number' },
    { title: 'Request', dataIndex: 'request_text', key: 'request_text' },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleString()
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          className="action-button"
          onClick={() => handleDeleteSupportRequest(record.request_id)}
          danger
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="admin-container">
      <h1 className="admin-title">Admin Dashboard</h1>

      <div className="admin-actions">
        <Button
          type="primary"
          onClick={() => setIsCreateModalVisible(true)}
          className="create-button"
        >
          Create New User
        </Button>
        <Button
          type="primary"
          onClick={handleExportUsers}
          className="export-button"
        >
          Export Users
        </Button>
      </div>

      <h2 className="section-title">Users</h2>
      <Table dataSource={users} columns={userColumns} rowKey="user_id"
        scroll={{ x: true }}
        pagination={{
          pageSize: 20,
          responsive: true,
          size: "small"
        }} />

      <h2 className="section-title">Profiles</h2>
      <Table dataSource={profiles} columns={profileColumns} rowKey="profile_id" scroll={{ x: true }}
        pagination={{
          pageSize: 20,
          responsive: true,
          size: "small"
        }} />

      <h2 className="section-title">Matches</h2>
      <Table dataSource={matches} columns={matchColumns} rowKey="matcher_id" scroll={{ x: true }}
        pagination={{
          pageSize: 5,
          responsive: true,
          size: "small"
        }} />

      <h2 className="section-title">Support Requests</h2>
      <Table
        dataSource={supportRequests}
        columns={supportRequestColumns}
        rowKey="request_id"
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
          responsive: true,
          size: "small"
        }}
      />

<Modal
  title="Update Profile"
  visible={isProfileModalVisible}
  onCancel={() => setIsProfileModalVisible(false)}
  footer={null}
  width={800}
>
  <Form onFinish={handleUpdateProfile} initialValues={currentProfile} layout="vertical">
    <Form.Item name="profile_id" label="Profile ID">
      <Input readOnly />
    </Form.Item>
    <Form.Item name="name" label="Name">
      <Input />
    </Form.Item>
    <Form.Item name="email" label="Email" rules={[{ type: 'email' }]}>
      <Input />
    </Form.Item>
    
    <Form.Item name="gender" label="Gender">
      <Select>
        <Select.Option value="">Select Gender</Select.Option>
        <Select.Option value="Male">Male</Select.Option>
        <Select.Option value="Female">Female</Select.Option>
        <Select.Option value="Other">Other</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="age" label="Age">
      <Input type="number" />
    </Form.Item>
    
    <Form.Item name="height" label="Height (cm)">
      <Input type="number" min="100" placeholder="Enter height in cm" />
    </Form.Item>
    
    <Form.Item name="weight" label="Weight (kg)">
      <Input type="number" placeholder="Enter weight in kgs" />
    </Form.Item>
    
    <Form.Item name="mother_tongue" label="Mother tongue">
      <Input placeholder="Enter mother tongue" />
    </Form.Item>
    
    <Form.Item name="complexion" label="Complexion">
      <Select>
        <Select.Option value="">Select Complexion</Select.Option>
        <Select.Option value="Fair">Fair</Select.Option>
        <Select.Option value="Light">Light</Select.Option>
        <Select.Option value="Medium">Medium</Select.Option>
        <Select.Option value="Olive">Olive</Select.Option>
        <Select.Option value="Tan">Tan</Select.Option>
        <Select.Option value="Dark">Dark</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="body_type" label="Body Type">
      <Select>
        <Select.Option value="">Select Body Type</Select.Option>
        <Select.Option value="Slim">Slim</Select.Option>
        <Select.Option value="Athletic">Athletic</Select.Option>
        <Select.Option value="Average">Average</Select.Option>
        <Select.Option value="Heavy">Heavy</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="marital_status" label="Marital Status">
      <Select>
        <Select.Option value="">Select Marital Status</Select.Option>
        <Select.Option value="Single">Single</Select.Option>
        <Select.Option value="Married">Married</Select.Option>
        <Select.Option value="Divorced">Divorced</Select.Option>
        <Select.Option value="Widowed">Widowed</Select.Option>
      </Select>
    </Form.Item>
    

    
    <Form.Item name="diet" label="Diet">
      <Select>
        <Select.Option value="">Select Diet</Select.Option>
        <Select.Option value="Vegetarian">Vegetarian</Select.Option>
        <Select.Option value="Non-Vegetarian">Non-Vegetarian</Select.Option>
        <Select.Option value="Vegan">Vegan</Select.Option>
        <Select.Option value="Other">Other</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="drink" label="Drink">
      <Select>
        <Select.Option value="">Do you Drink?</Select.Option>
        <Select.Option value="Yes">Yes</Select.Option>
        <Select.Option value="No">No</Select.Option>
        <Select.Option value="Occasionally">Occasionally</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="smoke" label="Smoke">
      <Select>
        <Select.Option value="">Do you Smoke?</Select.Option>
        <Select.Option value="Yes">Yes</Select.Option>
        <Select.Option value="No">No</Select.Option>
        <Select.Option value="Occasionally">Occasionally</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="blood_group" label="Blood Group">
      <Select>
        <Select.Option value="">Select Blood Group</Select.Option>
        <Select.Option value="A+">A+</Select.Option>
        <Select.Option value="A-">A-</Select.Option>
        <Select.Option value="B+">B+</Select.Option>
        <Select.Option value="B-">B-</Select.Option>
        <Select.Option value="AB+">AB+</Select.Option>
        <Select.Option value="AB-">AB-</Select.Option>
        <Select.Option value="O+">O+</Select.Option>
        <Select.Option value="O-">O-</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="specially_abled" label="Specially Abled">
      <Select>
        <Select.Option value="">Select</Select.Option>
        <Select.Option value="yes">Yes</Select.Option>
        <Select.Option value="no">No</Select.Option>
      </Select>
    </Form.Item>
    
    <h3>Education & Profession</h3>
    <Form.Item name="education" label="Education">
      <Select>
        <Select.Option value="">Select Education Level</Select.Option>
        <Select.Option value="High School">High School</Select.Option>
        <Select.Option value="Intermediate">Intermediate</Select.Option>
        <Select.Option value="Bachelor's">Bachelor's</Select.Option>
        <Select.Option value="Master's">Master's</Select.Option>
        <Select.Option value="PhD">PhD</Select.Option>
        <Select.Option value="Other">Other</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="profession" label="Profession">
      <Input placeholder="Enter your profession" />
    </Form.Item>
    
    <Form.Item name="income" label="Income (LPA)">
      <Input placeholder="Enter your income (LPA)" />
    </Form.Item>
    
    <h3>Location & Birth</h3>
    <Form.Item name="location_residence" label="Location of Residence">
      <Input placeholder="Enter your location of residence" />
    </Form.Item>
    
    <Form.Item name="place_of_birth" label="Place of Birth">
      <Input placeholder="Enter your place of birth" />
    </Form.Item>
    
    <Form.Item name="date_of_birth" label="Date of Birth">
      <Input type="date" />
    </Form.Item>
    
    <h3>Family Information</h3>
    <Form.Item name="father_name" label="Father's Name">
      <Input placeholder="Enter your father's name" />
    </Form.Item>
    
    <Form.Item name="father_occupation" label="Father's Occupation">
      <Input placeholder="Enter your father's occupation" />
    </Form.Item>
    
    <Form.Item name="mother_name" label="Mother's Name">
      <Input placeholder="Enter your mother's name" />
    </Form.Item>
    
    <Form.Item name="mother_occupation" label="Mother's Occupation">
      <Input placeholder="Enter your mother's occupation" />
    </Form.Item>
    
    <Form.Item name="sibling_count" label="Number of Siblings">
      <Input placeholder="Enter number of siblings" />
    </Form.Item>
    
    <Form.Item name="sibling_married" label="Siblings Married">
      <Select>
        <Select.Option value="">Select</Select.Option>
        <Select.Option value="yes">Yes</Select.Option>
        <Select.Option value="no">No</Select.Option>
      </Select>
    </Form.Item>
    
    <Form.Item name="sibling_details" label="Sibling Details">
      <Input.TextArea placeholder="Enter details about your siblings" />
    </Form.Item>
    
    <Form.Item>
      <Button type="primary" htmlType="submit">Update</Button>
    </Form.Item>
  </Form>
</Modal>

      <Modal
        title="Update Match"
        visible={isMatchModalVisible}
        onCancel={() => setIsMatchModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleUpdateMatch} initialValues={currentMatch}>
          <Form.Item name="matched_user_id" label="Matched User ID" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select>
              <Select.Option value="new">New</Select.Option>
              <Select.Option value="contacted">Contacted</Select.Option>
              <Select.Option value="pending">Pending</Select.Option>
              <Select.Option value="matched">Matched</Select.Option>
              <Select.Option value="rejected">Rejected</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">Update</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Create New User and Profile"
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        footer={null}
        width={800}
      >
        <Form onFinish={handleCreateUserAndProfile} layout="vertical">
          <h3>Basic Information</h3>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="mobile_number" label="Mobile" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
            <Select>
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value="Female">Female</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="looking_for" label="Looking For" rules={[{ required: true }]}>
          <Select>
            <Select.Option value="">Select</Select.Option>
            <Select.Option value="Myself">Myself</Select.Option>
            <Select.Option value="Brother">Brother</Select.Option>
            <Select.Option value="Daughter">Daughter</Select.Option>
            <Select.Option value="Son">Son</Select.Option>
            <Select.Option value="Sister">Sister</Select.Option>
            <Select.Option value="Friend">Friend</Select.Option>
          </Select>
        </Form.Item>
          <Form.Item name="height" label="Height (cm)">
            <Input type="number" />
          </Form.Item>
          <Form.Item name="weight" label="Weight (kg)">
            <Input type="number" />
          </Form.Item>
          <Form.Item name="mother_tongue" label="Mother Tongue">
            <Input />
          </Form.Item>
          <Form.Item name="complexion" label="Complexion">
            <Select>
              <Select.Option value="Fair">Fair</Select.Option>
              <Select.Option value="Light">Light</Select.Option>
              <Select.Option value="Medium">Medium</Select.Option>
              <Select.Option value="Olive">Olive</Select.Option>
              <Select.Option value="Tan">Tan</Select.Option>
              <Select.Option value="Dark">Dark</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="body_type" label="Body Type">
            <Select>
              <Select.Option value="Slim">Slim</Select.Option>
              <Select.Option value="Athletic">Athletic</Select.Option>
              <Select.Option value="Average">Average</Select.Option>
              <Select.Option value="Heavy">Heavy</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="marital_status" label="Marital Status">
            <Select>
              <Select.Option value="Single">Single</Select.Option>
              <Select.Option value="Married">Married</Select.Option>
              <Select.Option value="Divorced">Divorced</Select.Option>
              <Select.Option value="Widowed">Widowed</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="have_children" label="Have Children">
            <Select>
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="diet" label="Diet">
            <Select>
              <Select.Option value="Vegetarian">Vegetarian</Select.Option>
              <Select.Option value="Non-Vegetarian">Non-Vegetarian</Select.Option>
              <Select.Option value="Vegan">Vegan</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="drink" label="Drink">
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
              <Select.Option value="Occasionally">Occasionally</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="smoke" label="Smoke">
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
              <Select.Option value="Occasionally">Occasionally</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="blood_group" label="Blood Group">
            <Select>
              <Select.Option value="A+">A+</Select.Option>
              <Select.Option value="A-">A-</Select.Option>
              <Select.Option value="B+">B+</Select.Option>
              <Select.Option value="B-">B-</Select.Option>
              <Select.Option value="AB+">AB+</Select.Option>
              <Select.Option value="AB-">AB-</Select.Option>
              <Select.Option value="O+">O+</Select.Option>
              <Select.Option value="O-">O-</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="specially_abled" label="Specially Abled">
            <Select>
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>

          <h3>Education & Profession</h3>
          <Form.Item name="education" label="Education">
            <Select>
              <Select.Option value="High School">High School</Select.Option>
              <Select.Option value="Intermediate">Intermediate</Select.Option>
              <Select.Option value="Bachelor's">Bachelor's</Select.Option>
              <Select.Option value="Master's">Master's</Select.Option>
              <Select.Option value="PhD">PhD</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="profession" label="Profession">
            <Input />
          </Form.Item>
          <Form.Item name="income" label="Income (LPA)">
            <Input />
          </Form.Item>

          <h3>Religion & Caste</h3>
          <Form.Item name="religion" label="Religion">
            <Select>
              <Select.Option value="Hindu">Hindu</Select.Option>
              <Select.Option value="Muslim">Muslim</Select.Option>
              <Select.Option value="Christian">Christian</Select.Option>
              <Select.Option value="Sikh">Sikh</Select.Option>
              <Select.Option value="Jain">Jain</Select.Option>
              <Select.Option value="Buddhist">Buddhist</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="caste" label="Caste">
            <Input />
          </Form.Item>
          <Form.Item name="manglik" label="Manglik">
            <Select>
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>

          <h3>Location & Birth</h3>
          <Form.Item name="location_residence" label="Location of Residence">
            <Input />
          </Form.Item>
          <Form.Item name="place_of_birth" label="Place of Birth">
            <Input />
          </Form.Item>
          <Form.Item name="date_of_birth" label="Date of Birth">
            <Input type="date" />
          </Form.Item>

          <h3>Family Information</h3>
          <Form.Item name="father_name" label="Father's Name">
            <Input />
          </Form.Item>
          <Form.Item name="father_occupation" label="Father's Occupation">
            <Input />
          </Form.Item>
          <Form.Item name="mother_name" label="Mother's Name">
            <Input />
          </Form.Item>
          <Form.Item name="mother_occupation" label="Mother's Occupation">
            <Input />
          </Form.Item>
          <Form.Item name="sibling_count" label="Number of Siblings">
            <Input type="number" />
          </Form.Item>
          <Form.Item name="sibling_married" label="Siblings Married">
            <Select>
              <Select.Option value="yes">Yes</Select.Option>
              <Select.Option value="no">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="sibling_details" label="Sibling Details">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">Create Profile</Button>
          </Form.Item>
        </Form>
      </Modal>

    </div>
  );
};

export default AdminPage;