import React from 'react';
import { Button, Space } from 'antd';
import { WhatsAppOutlined, FacebookOutlined, TwitterOutlined, MessageOutlined } from '@ant-design/icons';

const ShareProfile = ({ userId, userName }) => {
  const profileURL = encodeURIComponent(`https://www.rishtajunction.com/userprofile/${userId}`);
  const message = encodeURIComponent(`Check out ${userName}'s profile on Rishta Junction: `);

  const shareLinks = {
    whatsapp: `https://api.whatsapp.com/send?text=${message}${profileURL}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${profileURL}`,
    twitter: `https://twitter.com/intent/tweet?url=${profileURL}&text=${message}`,
    sms: `sms:?&body=${message}${profileURL}`,
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button 
        type="primary" 
        icon={<WhatsAppOutlined />} 
        style={{ backgroundColor: '#25D366', width: '100%' }}
        href={shareLinks.whatsapp} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        WhatsApp
      </Button>
      <Button 
        type="primary" 
        icon={<FacebookOutlined />} 
        style={{ backgroundColor: '#1877F2', width: '100%' }}
        href={shareLinks.facebook} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Facebook
      </Button>
      <Button 
        type="primary" 
        icon={<TwitterOutlined />} 
        style={{ backgroundColor: '#1DA1F2', width: '100%' }}
        href={shareLinks.twitter} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Twitter
      </Button>
      <Button 
        type="primary" 
        icon={<MessageOutlined />} 
        style={{ backgroundColor: '#5BC236', width: '100%' }}
        href={shareLinks.sms} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        SMS
      </Button>
    </Space>
  );
};

export default ShareProfile;
